import { graphql } from 'gatsby'
import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { createGlobalStyle } from "styled-components"
import Seo from "../components/seo"
import Image from "../components/image"

import NGCIlogo from "../images/democraticDay-title-horizontal.svg"

// CSSモジュール
import * as style from "../components/index.module.css"

const GlobalStyle = createGlobalStyle`
#gatsby-focus-wrapper {
  background: transparent radial-gradient(closest-side at 50% 8%,#FEFEFE 0%,#DADADA 100%) 0% 0% no-repeat padding-box;
}
`

export const query = graphql`
query {
    site: site {
      id
      siteMetadata {
        author
        backgroundColor
        description
        domain
        shortName
        siteLanguage
        title
        url
      }
    }

    top: allMarkdownRemark(filter: {frontmatter: {page: {eq: "top"}}}) {
        edges {
            node {
                fileAbsolutePath
                frontmatter {
                  description
                  eventEndDay
                  eventEndMonth
                  eventEndTime
                  eventEndWeek
                  eventStartDay
                  eventStartMonth
                  eventStartTime
                  eventStartWeek
                  eventYear
                  order
                  organizer
                  page
                  price
                  title
                  venue
                  subscriptionURL
                }
                html
            }
        }
    }

    leaf: allMarkdownRemark(
      filter: {frontmatter: {page: {in: ["concept","overview"]}}}
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      edges {
          node {
              fileAbsolutePath
              frontmatter {
                page
                title
                description
                order
              }
              html
              rawMarkdownBody
          }
      }
    }

    speakers: allSpeakers202110Yaml(sort: {fields: order, order: ASC}) {
      edges {
        node {
          message
          name
          order
          profileText
          url
          image
          id
          copyText
        }
      }
    }

    members: allMembersYaml {
      edges {
        node {
          id
          name
          profileText
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const { top } = props.data;
  const { leaf } = props.data;
  const { speakers } = props.data;
  const { members } = props.data;

  const topData = top.edges[0].node
  let isEndDate = (topData.frontmatter.eventEndMonth) ? true : false

  const leafDatas = leaf.edges.map(({ node }) => {
    return node
  })

  const speakerDatas = speakers.edges.filter((v) => v.node.order !== '');
  const memberDatas = members.edges.map(({ node }) => {
    return node
  })

  return (
  <Layout>
    <React.Fragment>
        <GlobalStyle />
    </React.Fragment>
    <Seo />
    
    <section className={`${style.topTitle}`}>
      <div className={`${style.topTitleFrame}`}>
        <figure className={`${style.title}`}>
          <svg viewBox={NGCIlogo.viewBox}><use xlinkHref={NGCIlogo.url}/></svg>
          <ul className={`${style.logos}`}>
            <li><a href="https://cyber.nakao-lab.org/" target="_blank" rel="noreferrer"><StaticImage src="../images/logo_NGCI.png" alt="NGCI" layout="fixed" placeholder="NGCI" /></a></li>
            <li><a href="https://www.u-tokyo.ac.jp/adm/fsi/ja/symposium.html" target="_blank" rel="noreferrer"><StaticImage src="../images/logo_FSI.png" alt="UTokyo FSI Symposium" layout="fixed" placeholder="UTokyo FSI Symposium" /></a></li>
            <li><a href="https://digital-days.digital.go.jp" target="_blank" rel="noreferrer"><StaticImage src="../images/logo_JapanDigitalDay.png" alt="2021年デジタルの日" layout="fixed" placeholder="2021年デジタルの日" /></a></li>
          </ul>
        </figure>

        <p>{topData.frontmatter.description}</p>

        <div className={`${style.startDateFrame}`}>
          <span>{topData.frontmatter.eventYear}</span>
          <span>{topData.frontmatter.eventStartMonth}</span>
          <span>{topData.frontmatter.eventStartDay}</span>
          <span>{topData.frontmatter.eventStartWeek}</span>
          <span>{topData.frontmatter.eventStartTime}</span>

          {isEndDate && (
            <div>
              <span>{topData.frontmatter.eventYear}</span>
              <span>{topData.frontmatter.eventEndMonth}</span>
              <span>{topData.frontmatter.eventEndDay}</span>
              <span>{topData.frontmatter.eventEndWeek}</span>
              <span>{topData.frontmatter.eventEndTime}</span>
            </div>
          )}
        </div>

        <figure className={`${style.peopleBG} forPC`}>
          <StaticImage src="../images/topPage-people-bg.png" alt="people" layout="fixed" placeholder="tracedSVG" />
        </figure>
        <figure className={`${style.peopleBG} forSP`}>
          <StaticImage src="../images/topPage-people-bg_sp.png" alt="people" layout="fixed" placeholder="tracedSVG" />
        </figure>
      </div>
    </section>

    {leafDatas.map(( leaf,i ) => {

      let isConcept = (leaf.frontmatter.page === 'concept') ? true : false
      let isOverview = (leaf.frontmatter.page === 'overview') ? true : false
      let isSpeakers = (leaf.frontmatter.page === 'speakers') ? true : false

      return(
        <section id={`${leaf.frontmatter.page}`} className={`${style.section} ${leaf.frontmatter.page}`}>
          <div className={`${style.sectionFrame} section-frame`}>
            <h2>{leaf.frontmatter.title}</h2>
            
            {isConcept && (
              <div className={`${style.conceptFrame}`}>
                <div>
                  <div className={`${style.conceptTextFrame}`} dangerouslySetInnerHTML={{ __html: leaf.html }} />
                </div>
              </div>
            )}

            {isOverview && (
              <div className={`${style.overviewFrame}`}>
                <div className={`${style.overviewTextFrame}`}>
                  <div dangerouslySetInnerHTML={{ __html: leaf.html }} />
                </div>
                <figure className={`${style.overviewBgFrame}`}>
                  <StaticImage src="../images/topPage-overview-bg.png" alt="overview" layout="fixed" placeholder="tracedSVG" />
                </figure>
                <Link to={`/program/`} className={`${style.buttonFrame}`}>プログラムはこちら</Link>
              </div>
            )}

            {isSpeakers && (
              <ul className={`${style.speakersFrame}`}>
                {speakerDatas.map(( speaker,i ) => {
                  return(
                    <li>
                      <figure>
                        <Image filename={`${speaker.node.image}`} alt={`${speaker.node.name}`} />
                      </figure>
                      <div className={`${style.speakersInfo}`}>
                        <p>{speaker.node.copyText}</p>
                        <h3>{speaker.node.name}</h3>
                        <span>{speaker.node.profileText}</span>
                        <p>{speaker.node.message}</p>
                      </div>
                    </li>
                  )
                })}
              </ul>
            )}
            {isSpeakers && (
              <Link to={`/program/`} className={`${style.buttonFrame}`}>プログラムはこちら</Link>
            )} 
          </div>
        </section>
      )
    })}

    <section className={`${style.members}`}>
      <div className={`${style.membersFrame}`}>
        <h2>NGCIデモクラティックデイプログラム実行委員会メンバー</h2>
        <ul>
          {memberDatas.map(( member,i ) => {
            return(
              <li><span>{member.name}</span><span>{member.profileText}</span></li>
            )
          })}
        </ul>
      </div>
    </section>

    {/* <a href={`${topData.frontmatter.subscriptionURL}`} target="_blank" rel="noreferrer" className={`${style.buttonFrame}`}>お申し込みはこちら</a> */}
  
  </Layout>
  )
}

export default IndexPage
